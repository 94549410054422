

import { Vue, Component } from 'vue-property-decorator'
import { Material } from '@/types/material.d'

@Component
export default class ManageDetail extends Vue {
  private formData = {
    materialName: '', // 物料名称
    materialCode: '', // 物料编码
    materialTypeId: '', // 物料分类
    materialTypeName: '',
    company: '', // 单位
    specifications: '', // 规格
    state: '1' // 状态1启用2禁用
  }

  created () {
    this.loadData()
  }

  get materialId () {
    return this.$route.query.materialId as string
  }

  loadData () {
    this.$axios.get<Material>(this.$apis.material.selectYhMaterialByMaterialId, {
      materialId: this.materialId
    }).then(res => {
      this.formData = res
    })
  }
}
